.bp-list {
    overflow-y: auto;
}

.bp-list-sm {
    max-height: 10em;
}

.bp-list-md {
    max-height: 20em;
}

.bp-list-ld {
    max-height: 30em;
}